import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CloseIcon, IconWrapper, useDialog, UserRightIcon } from '@skywatch/ui'
import { AutoComplete } from '@drive/shared'
import { getEmailsEndpoint } from '~/api'
import { UserEmail } from '~/models'

const fetchEmails = async (query: string) => {
  return (await getEmailsEndpoint(null, { queryParams: { username: query } })) as any
}

export default () => {
  const { Dialog, setOpen } = useDialog()
  const navigate = useNavigate()
  const [application, setApplication] = useState<UserEmail | null>()

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (!application) return
    navigate(`/user/${application.userId}/insurance`)
  }

  const ContinueQuoteDialog = () => {
    return (
      <Dialog>
        <div className="flex justify-between">
          <div className="relative mb-2">
            <IconWrapper type="primary">
              <UserRightIcon className="stroke-primary-500 stroke-2" />
            </IconWrapper>
          </div>
          <div>
            <button onClick={() => setOpen(false)}>
              <CloseIcon className="stroke-gray-500 stroke-2" />
            </button>
          </div>
        </div>
        <div>
          <div className="mb-1 text-lg font-semibold">Continue a Quote</div>
          <div className="mb-5 text-gray-500">Please insert customer email</div>
          <form id="modal" onSubmit={onSubmit}>
            <AutoComplete onChange={setApplication} fetchEndpoint={fetchEmails} />
          </form>
        </div>
        <div className="mt-3 grid grid-cols-6 gap-3">
          <Button buttonType={'gray'} variant={'outline'} className="col-span-3" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button buttonType={'primary'} variant={'standard'} className="col-span-3" type="submit" form="modal">
            Continue
          </Button>
        </div>
      </Dialog>
    )
  }

  return { ContinueQuoteDialog, setOpen }
}
